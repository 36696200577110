import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import accountClient from 'api/accountClient';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiryWrapper';
import { getAzureLoginUrl, SignUpSignInJourneyStep } from 'helpers/azureRoutingHelper';
import { getHashParam } from 'helpers/getQueryParam';
import { accountRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import useAzureErrorHandler from 'helpers/useAzureErrorHandler';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';

type LoginProps = {
  data: {
    csLogin: {
      meta: CsMeta;
    };
  };
  location: Location & {
    state?: { email: string };
  };
};

export const query = graphql`
  query {
    csLogin {
      meta {
        meta_title
      }
    }
  }
`;

const Login: React.FC<LoginProps> = ({
  data: {
    csLogin: { meta },
  },
  location,
}) => {
  const [user, setUser] = useUser();
  const [loginAttempted, setLoginAttempted] = useReducer(() => true, false);
  const { requestHandler } = useApiRequestHandler();
  const azureErrorHandler = useAzureErrorHandler();
  const dispatch = useDispatch();

  const idToken = getHashParam(location, 'id_token');
  const error = getHashParam(location, 'error');

  const loginWithToken = useCallback(
    (token: string) => {
      requestHandler(() => {
        setLoginAttempted();
        return accountClient.login(token).then((response) => {
          setUser({ isLoggedIn: true });

          navigate(accountRoutes.account, {
            replace: true,
            state: {
              successfulPasswordChange: response.hasPasswordChanged,
              successfulEmailChange: response.hasEmailChanged,
            },
          });
        });
      });
    },
    [requestHandler, setUser]
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    if (error) {
      azureErrorHandler(location);
      return;
    }
    if (idToken && loginAttempted) {
      return;
    }
    if (idToken) {
      loginWithToken(idToken);
      return;
    }
    if (user.isLoggedIn) {
      navigate(accountRoutes.account);
      return;
    }
    requestHandler(() =>
      accountClient.getNonce().then(({ nonce }) => {
        window.location.replace(
          getAzureLoginUrl(SignUpSignInJourneyStep.Login, nonce, location.state?.email)
        );
      })
    );
  }, [
    azureErrorHandler,
    dispatch,
    error,
    idToken,
    location,
    loginAttempted,
    loginWithToken,
    requestHandler,
    setUser,
    user,
  ]);

  return (
    <Layout
      meta={meta}
      pageType="accountArea"
      sessionExpiryOption={SessionExpiryOption.SILENT_SESSION_EXPIRY}
      hideAccountMenu>
      {!idToken && <LoadingOverlay loadingMessage="Fetching user session" />}
      {idToken && <LoadingOverlay loadingMessage="Logging you in" />}
    </Layout>
  );
};

export default Login;
